.sidebar_wrap ul{
    list-style: none;
    padding: 0;
    margin: 0;
}

/* styles.css */
.image-container {
    text-align: center;
    margin-top: 5em;
  }
  
  .enlarged {
    transform: scale(2.5);
    transition: transform 0.25s ease;
  }
  