/* modal.css */
/* Style for the modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Style for the modal content */
.modal-content {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  padding: 20px;
  max-width: 70%; /* Adjust the width as needed */
  max-height: 70%; /* Adjust the height as needed */
  overflow: auto;
  position: relative;
}

/* Style for the close button */
.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: 18px;
}

/* Hover effect for the close button */
.modal-close:hover {
  color: rgb(41, 11, 116);
}
