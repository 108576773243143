@import "fonts/Fonts.css";
html,
body,
#root {
  height: 100%;
}
body {
  font-family: "Montserrat", sans-serif !important;
}
.form-wrap label.form-label {
  color: #222222;
  font-size: 14px;
  font-weight: 400;
  word-wrap: break-word;
  margin-bottom: 4px;
  display: block;
  text-align: left;
}
.form-wrap input,
.form-wrap select {
  border-radius: 5px;
  border: 0.5px #aaaaaa solid;
  width: 100%;
  margin-bottom: 30px;
  font-weight: 700;
  word-wrap: break-word;
  padding: 13px 20px;
  color: #012269;
  font-size: 13px;
  font-weight: 700;
  word-wrap: break-word;
}
.form-wrap input:focus,
.form-wrap select:focus {
  outline: none;
  box-shadow: none;
}
.btn.btn-prim {
  padding: 10px 20px;
  width: 100%;
  line-height: 19px;
  font-size: 13px;
  font-weight: 700;
  word-wrap: break-word;
  background: rgba(1, 34, 105, 1);
  color: #fff;
  border: 1px solid rgba(1, 34, 105, 1);
  transition: 0.3s;
}
.btn.btn-prim:hover,
.btn.btn-prim:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid rgba(1, 34, 105, 1);
  background: #fff;
  color: rgba(1, 34, 105, 1);
}
.btn.btn-prim.btn-transparent {
  border: 1px solid rgba(1, 34, 105, 1);
  background: #fff;
  color: rgba(1, 34, 105, 1);
}
.btn.btn-prim.btn-transparent:hover {
  border: 1px solid rgba(1, 34, 105, 1);
  background: rgba(1, 34, 105, 1);
  color: #fff;
}
.modal-footer .btn.btn-prim {
  width: auto;
  min-width: 100px;
}
.form-wrap a {
  color: #222222;
  font-size: 13px;
  font-weight: 700;
  word-wrap: break-word;
  margin-top: 30px;
  display: inline-block;
  text-decoration: none;
  padding-bottom: 15px;
}
/* form css */
.body-wrap {
  overflow-x: hidden;
}
/* sidebar */
.col.col-sidebar {
  background: #fff;
  height: 100%;
  max-width: 100%;
  width: 300px;
  flex: 0 0 auto;
  position: fixed;
  top: 0;
  /* left: 0; */
  overflow-x: hidden;
  overflow-y: auto;
}
/* width */
.col.col-sidebar::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.col.col-sidebar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.col.col-sidebar::-webkit-scrollbar-thumb {
  background: rgba(1, 34, 105, 1);
  border-radius: 10px;
}

/* Handle on hover */
.col.col-sidebar::-webkit-scrollbar-thumb:hover {
  background: rgba(1, 34, 105, 0.7);
}
.sidebar_wrap {
  padding: 30px 30px;
}
.logo-sidebar-wrap {
  margin-bottom: 35px;
}
section.sidebar_wrap ul li {
  padding: 13px 20px;
}
section.sidebar_wrap ul li a {
  color: #999999;
  font-size: 12px;
  font-weight: 400;
  word-wrap: break-word;
  text-decoration: none;
  transition: 0.3s;
}
section.sidebar_wrap ul li a.active,
section.sidebar_wrap ul li a:hover {
  color: #012269;
  font-size: 12px;
  font-weight: 700;
  word-wrap: break-word;
}
section.sidebar_wrap ul li img {
  width: 18px;
  object-fit: contain;
  margin-right: 20px;
  filter: brightness(0);
  opacity: 0.3;
  transition: 0.3s;
}
section.sidebar_wrap ul li .active img,
section.sidebar_wrap ul li a:hover img {
  filter: brightness(1);
  opacity: 1;
}
button#dropdownNot {
  display: flex;
  align-items: center;
  border-radius: 5px;
  background: #fff;
  width: 34px;
  height: 34px;
  margin-right: 30px;
  padding: 5px;
  text-align: center;
  justify-content: center;
  cursor: pointer;
}
button#dropdownNot:focus {
  outline: none;
}
button#dropdownNot:after {
  display: none;
}
ul.dropdown-menu {
  background: #fff;
  width: 16rem;
  border-radius: 5px;
  border: 0.5px #eeeeee solid;
  padding: 0;
  max-height: 60vh;
}
ul.dropdown-menu > li {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}
ul.dropdown-menu h3 {
  font-size: 15px;
  font-weight: 400;
  word-wrap: break-word;
  color: #222222;
  margin-bottom: 0;
}
span.not_num_icon {
  width: 14px;
  height: 14px;
  background: rgba(1, 34, 105, 1);
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 8px;
  font-weight: 400;
  word-wrap: break-word;
  border-radius: 7px;
}
ul.dropdown-menu li {
  padding-top: 12px;
  padding-bottom: 12px;
  padding: 0 10px;
}
ul.dropdown-menu .notification_heads {
  padding: 12px 10px;
}
ul.dropdown-menu > li li {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px 0;
  cursor: pointer;
  transition: 0.3s;
}
ul.dropdown-menu > li li.not_list.unread {
  background: rgba(1, 34, 105, 0.03);
}
ul.dropdown-menu .dropdown-item.active,
ul.dropdown-menu .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}

ul.dropdown-menu .dropdown-item:focus,
ul.dropdown-menu .dropdown-item:hover {
  color: var(--bs-dropdown-link-hover-color);
  background-color: transparent;
}
ul.dropdown-menu > li li:hover {
  background: rgba(1, 34, 105, 0.03);
}
.not_prof_wrap {
  max-width: 100%;
  flex: 0 0 auto;
  width: 30px;
  margin-right: 10px;
}
.not_time_wrap span {
  color: #aaaaaa;
  font-size: 10px;
  font-weight: 400;
  line-height: 12.5px;
  word-wrap: break-word;
  display: block;
  text-align: right;
}
/* sidebar */
.col.col-dashboard {
  height: 100%;
  max-width: 100%;
  width: calc(100% - 300px);
  flex: 0 0 auto;
  padding: 0 40px;
  margin-left: 300px;
}
header {
  padding: 20px 0 45px;
}
.wel-not h1 {
  color: #012269;
  font-size: 22px;
  font-weight: 700;
  word-wrap: break-word;
}
.prof-wrap {
  display: flex;
  align-items: center;
}
.prof-sett {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.det-prof {
  margin-right: 15px;
}
.name-wrap h5 {
  color: black;
  font-size: 15px;
  font-weight: 400;
  word-wrap: break-word;
}
.name-wrap p {
  color: #777777;
  font-size: 12px;
  font-weight: 400;
  word-wrap: break-word;
  margin-bottom: 0;
}
.btn.tb-btn,
.general-dashboard table td .link-edit {
  background-color: rgba(0, 62, 182, 0.07);
  color: #003eb6;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  word-wrap: break-word;
  padding: 8px 30px;
  margin-left: 20px;
  transition: 0.3s;
}
.btn.tb-btn:hover,
.link-edit.tb-btn:hover {
  background-color: #003eb6;
  color: #fff;
}
.btn.tb-btn,
.link-edit.tb-btn {
  padding: 8px 18px;
  min-width: 80px;
}
.btn.btn-delete {
  color: #b60000;
  background-color: rgba(182, 0, 0, 0.07);
  font-size: 12px;
  font-weight: 400;
}
.btn.btn-delete:hover {
  background-color: #b60000;
  color: #fff;
}
.btn.btn-success {
  color: #1f8c15;
  background-color: rgba(31, 140, 21, 0.07);
  font-size: 12px;
  font-weight: 400;
  border: 1px solid rgba(31, 140, 21, 0.07);
}
.btn.btn-success:hover {
  background-color: #1f8c15;
  border: 1px solid #1f8c15;
  color: #fff;
}
.btn.btn-prim.btn-danger {
  background: #b60000;
  border: 1px solid #b60000;
}
.btn.btn-prim.btn-danger:hover {
  background: #fff;
  border: 1px solid #b60000;
  color: #b60000;
}

/* tables dashboard */
.general-dashboard table {
  border-collapse: separate;
  border-spacing: 0 10px;
}
.dashboard-top-row {
  padding-bottom: 50px;
}
.user-wrap h3 {
  color: black;
  font-size: 20px;
  font-weight: 400;
  word-wrap: break-word;
  margin-bottom: 0;
}
.user-wrap p {
  color: #999999;
  font-size: 12px;
  font-weight: 400;
  word-wrap: break-word;
  margin-bottom: 0;
}
.filter-right-sec {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.filter-box,
.search-box {
  display: flex;
  align-items: center;
  border-radius: 5px;
  background: #fff;
  width: 40px;
  height: 40px;
  margin-right: 20px;
  padding: 5px;
  text-align: center;
  justify-content: center;
  cursor: pointer;
}
.general-dashboard table th {
  border-bottom: none;
  color: #222222;
  font-weight: 600;
  word-wrap: break-word;
  padding: 10px 20px;
}
.general-dashboard table tr {
  border: 0.5px #eeeeee solid;
}
.general-dashboard table td {
  background: #fff;
  border-bottom: none;
  padding: 10px 20px;
  color: #222222;
  font-size: 12px;
  font-weight: 400;
  word-wrap: break-word;
}
span.status.success {
  background: rgba(25, 182, 0, 0.07);
  display: inline-block;
  padding: 7px 15px;
  border-radius: 12px;
  color: #19b600;
  font-size: 10px;
  font-weight: 700;
  word-wrap: break-word;
}
span.status.danger {
  background: rgba(182, 0, 0, 0.07);
  display: inline-block;
  padding: 7px 15px;
  border-radius: 12px;
  color: #b60000;
  font-size: 10px;
  font-weight: 700;
  word-wrap: break-word;
}
span.status.warning {
  background: rgba(182, 120, 0, 0.07);
  display: inline-block;
  padding: 7px 15px;
  border-radius: 12px;
  color: rgba(182, 120, 0, 1);
  font-size: 10px;
  font-weight: 700;
  word-wrap: break-word;
}
.usr_det_tb {
  display: flex;
  align-items: center;
}
.usr_det_tb .img_wrap_user_tb {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: #eeeeee;
  margin-right: 30px;
}
.usr_det_tb .img_wrap_user_tb img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 20px;
}
.table-num {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;
}
.dig-num {
  display: flex;
  align-items: center;
}
.dig-num a {
  border-radius: 12px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #999999;
  font-size: 12px;
  font-weight: 400;
  word-wrap: break-word;
  margin-right: 5px;
}
.dig-num span,
.dig-num .current_pg,
.dig-num .pg_links.active,
.dig-num a.active {
  background: rgba(1, 34, 105, 0.1);
  border-radius: 12px;
  border: 0.25px #012269 solid;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #012269;
}
.num-btns a,
.dig-num .btn_pg {
  display: flex;
  align-items: center;
  border-radius: 5px;
  background: #fff;
  width: 40px;
  height: 40px;
  margin-right: 20px;
  padding: 5px;
  text-align: center;
  justify-content: center;
  cursor: pointer;
}
.num-btns {
  display: flex;
  align-items: center;
}
.user-wrap.user-back-wrap {
  display: flex;
}
.user-wrap.user-back-wrap > a {
  display: flex;
  align-items: center;
  border-radius: 5px;
  background: #fff;
  width: 40px;
  height: 40px;
  margin-right: 20px;
  padding: 5px;
  text-align: center;
  justify-content: center;
  cursor: pointer;
}
.dashboard-top-row.add-new-top-row {
  padding-bottom: 30px;
}
/* tables dashboard */
/* add dashbaord */
.form-box.card-box {
  background: white;
  border-radius: 10px;
  border: 0.5px #eeeeee solid;
  padding: 30px 20px;
}
.form-box.card-box h4 {
  color: #222222;
  font-size: 15px;
  font-weight: 400;
  word-wrap: break-word;
  margin-bottom: 5px;
}
.form-box.card-box p {
  color: #bbbbbb;
  font-size: 12px;
  font-weight: 400;
  word-wrap: break-word;
  margin-bottom: 0;
}
.inpt-wrap {
  margin-bottom: 30px;
}
.inpt-wrap input,
.inpt-wrap select,
.inpt-wrap textarea {
  color: #012269;
  font-size: 13px;
  line-height: 19px;
  font-weight: 700;
  word-wrap: break-word;
  background: white;
  border-radius: 5px;
  border: 0.5px #aaaaaa solid;
  padding: 12px 20px;
  width: 100%;
}
.inpt-wrap ::placeholder {
  /* color: #012269; */
}
input:focus,
select:focus {
  outline: none;
  box-shadow: none;
}
.file-wrap {
  position: relative;
  color: #012269;
  font-size: 13px;
  line-height: 19px;
  font-weight: 700;
  word-wrap: break-word;
  background: white;
  border-radius: 5px;
  border: 0.5px #aaaaaa solid;
  padding: 12px 20px;
  width: 100%;
}
.inpt-wrap .file-wrap input {
  position: absolute;
  z-index: 99;
  opacity: 0;
  left: 0;
  top: 0;
}
.form-btn-wrap .btn {
  min-width: 270px;
}
.img_wrap_qr_tb {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  background: rgba(0, 62, 182, 0.07);
}
.img_wrap_qr_tb img {
  /* margin-right: 20px; */
  /* width: 30px;
    height: 30px; */
  height: 100%;
  width: 100%;
  object-fit: cover;
}
/* add dashboard */
.option-selected {
  display: flex;
  align-items: center;
}
/* cahts wrap */
.col.col-user-accs {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  flex: 0 0 auto;
  width: 340px;
}
.col.col-user-msgs {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  flex: 0 0 auto;
  width: calc(100% - 340px);
}
.col.col-user-accs .nav-pills {
  display: block;
  padding: 12px 0;
  background-color: #fff;
  height: 100%;
  min-height: 644.25px;
}
.col.col-user-accs .nav-pills .nav-link {
  display: flex;
  flex-wrap: wrap;
  border-radius: 0;
  width: 100%;
}
.col.col-user-accs .nav-pills .nav-link {
  text-align: left;
}
.col.col-user-accs .nav-pills .nav-link .icon-wrap {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: rgba(186, 221, 186, 1);
  margin-right: 20px;
  flex-shrink: 0;
  max-width: 100%;
  flex: 0 0 auto;
}
.col.col-user-accs .nav-pills .nav-link:nth-child(even) .icon-wrap {
  background: rgba(214, 186, 221, 1);
}
.col.col-user-accs .nav-pills .nav-link .icon-wrap img {
  height: 100%;
  width: 100%;
  border-radius: 20px;
}
.col.col-user-accs .nav-pills .nav-link .usr-det {
  flex-shrink: 0;
  max-width: 100%;
  flex: 0 0 auto;
  width: calc(100% - 60px);
}
.col.col-user-accs .nav-pills .nav-link .usr-det h4 {
  color: #222222;
  font-size: 15px;
  font-weight: 400;
  line-height: 26.25px;
  word-wrap: break-word;
  margin-bottom: 0;
}
.col.col-user-accs .nav-pills .nav-link .usr-det p {
  margin-bottom: 0;
  color: #999999;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  word-wrap: break-word;
}
html body .nav-pills .nav-link.active,
html body .nav-pills .show > .nav-link {
  background-color: rgba(1, 34, 105, 0.05);
}
/* message screen */
.col.col-user-msgs .icon-wrap {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: rgba(186, 221, 186, 1);
  margin-right: 20px;
  flex-shrink: 0;
  max-width: 100%;
  flex: 0 0 auto;
}
.col.col-user-msgs .icon-wrap img {
  height: 100%;
  width: 100%;
  border-radius: 20px;
}
.message-body {
  border-radius: 10px;
  border: 0.5px #eeeeee solid;
  background: #fff;
  padding: 20px 0;
}
.message-top-bar-user-wrap {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 25px;
}
.message-top-bar-user {
  padding: 0 20px;
}
.name-job h3 {
  color: #222222;
  font-size: 15px;
  font-weight: 400;
  line-height: 26.25px;
  word-wrap: break-word;
}
.name-job p {
  color: #999999;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  word-wrap: break-word;
}
.icon-ph-wrap {
  background: #f5f5f5;
  border-radius: 8px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-ph-wrap {
  background: #f5f5f5;
  border-radius: 8px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}
.cht-wrap {
  padding: 20px 20px;
  height: 450px;
  overflow-y: scroll;
}
.msg-line {
  padding: 10px 0;
  width: 80%;
}
.msg-line p {
  color: #222222;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  line-height: 16px;
  word-wrap: break-word;
  background: #f5f5f5;
  border-radius: 30px;
  padding: 10px 20px;
  margin-bottom: 0;
  display: inline-block;
}
.snt-msg.msg-line {
  direction: rtl;
  margin: 0 0 0 auto;
}
.snt-msg.msg-line p {
  color: #012269;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  line-height: 16px;
  word-wrap: break-word;
  background: #fff;
  border: 0.5px #012269 solid;
}
.send-wrap {
  padding: 20px 20px;
  background-color: #fff;
}
.inpt-fld input {
  background: white;
  border-radius: 5px;
  border: 0.5px #aaaaaa solid;
  width: 100%;
  color: #aaaaaa;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  word-wrap: break-word;
  padding: 10px 20px;
}
.col.input-fld {
  max-width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  flex: 0 0 auto;
  width: calc(100% - 130px);
}
.col.input-btn {
  max-width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  flex: 0 0 auto;
  width: 130px;
}
.col.input-btn .btn.btn-prim {
  color: white;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  word-wrap: break-word;
  width: 100%;
  margin-left: 0;
}
.col.input-btn .btn.btn-prim:hover {
  color: rgba(1, 34, 105, 1) !important;
}
.btn-check.btn-prim:checked + .btn.btn-prim:focus-visible,
.btn.btn-prim.active:focus-visible,
.btn.btn-prim.show:focus-visible,
.btn.btn-prim:first-child:active:focus-visible,
:not(.btn-check) + .btn.btn-prim:active:focus-visible,
.btn.btn-prim:focus {
  border: 1px solid rgba(1, 34, 105, 1) !important;
  background: #fff !important;
  color: rgba(1, 34, 105, 1) !important;
}
.incoming-call-sec h3 {
  color: black;
  font-size: 22px;
  font-weight: 700;
  line-height: 38.5px;
  letter-spacing: 2.2px;
  word-wrap: break-word;
  margin-bottom: 30px;
}
.call-detail-bar {
  background: rgba(0, 0, 0, 0.1);
  padding: 20px 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rcvr-img {
  border-radius: 20px;
  border: 1px rgba(0, 0, 0, 0.1) solid;
  width: 120px;
  height: 120px;
  margin-right: 20px;
}
.rcvr-img {
  border-radius: 20px;
  object-fit: cover;
  width: 120px;
  height: 120px;
}
.rcvr-detail {
  display: flex;
}
.incoming-call-sec .rcvr-naming h3 {
  margin-bottom: 0;
  color: black;
  font-size: 25px;
  font-weight: 400;
  line-height: 43.75px;
  word-wrap: break-word;
  padding-top: 10px;
}
.rcvr-naming p {
  color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  word-wrap: break-word;
  margin-bottom: 0;
}
.icons-wrap-options {
  display: flex;
}
.icon-dec {
  background: #e72626;
  border-radius: 20px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-att-voice,
.icon-att-vid {
  background: #30b62e;
  border-radius: 20px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}
.message-content-area {
  background: white;
  border-radius: 10px;
  border: 0.5px #eeeeee solid;
}
.col.col-chat-screen {
  flex-shrink: 0;
  max-width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  flex: 0 0 auto;
  width: 390px;
}
.col.col-video-screen {
  flex-shrink: 0;
  max-width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  flex: 0 0 auto;
  width: calc(100% - 390px);
}
.vide-img-wrap {
  position: relative;
  height: 100%;
}
.vide-img-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.vide-img-wrap img.shadow-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.vide-sec-whole-wrap {
  position: relative;
  max-height: 500px;
  height: 100%;
}
.top-bar-call-vid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.call-time {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  backdrop-filter: blur(5px);
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 8px 20px;
  min-width: 100px;
  text-align: center;
}
.call-time p {
  margin-bottom: 0;
}

.col.col-chat-screen .col.input-btn {
  width: 100px;
}
.col.col-chat-screen .col.input-fld {
  width: calc(100% - 100px);
}
.bottom-bar-call {
  position: absolute;
  width: 100%;
  bottom: 20px;
  z-index: 999;
  text-align: center;
}
.icons-wrap-end-call {
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-call {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 15px;
  backdrop-filter: blur(4px);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}
.dec-call-video {
  backdrop-filter: blur(4px);
  width: 60px;
  height: 60px;
  background-color: rgba(231, 38, 38, 1);
}
.bottom-bar-call.seperat-row {
  position: unset;
  padding-top: 20px;
}
.bottom-bar-call.seperat-row .icon-call {
  background-color: rgba(0, 0, 0, 0.25);
}
.bottom-bar-call.seperat-row .dec-call-video {
  backdrop-filter: blur(4px);
  width: 60px;
  height: 60px;
  background-color: rgba(231, 38, 38, 1);
}
.message-body .icon-wrap {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: rgba(186, 221, 186, 1);
  margin-right: 20px;
  flex-shrink: 0;
  max-width: 100%;
  flex: 0 0 auto;
}
.message-body .icon-wrap img {
  height: 100%;
  width: 100%;
  border-radius: 20px;
}
.col.col-chat-screen .cht-wrap {
  padding: 20px 20px;
  height: 305px;
  overflow: auto;
}
.general-dashboard .way_find_table table td:nth-last-child(1) {
  text-align: end;
}
.btn-add-wrap {
  display: flex;
  justify-content: space-between;
}
.btn.btn-gray {
  background: #eeeeee;
  border-radius: 5px;
  color: #222222;
  font-size: 13px;
  font-weight: 400;
  word-wrap: break-word;
  padding: 12px 20px;
  min-width: 135px;
}
.upld-img-wrap {
  width: 100%;
  height: 90px;
  background: #ffd5dc;
  border-radius: 5px;
  margin: 20px 0;
  position: relative;
}
.upld-img-wrap img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
}
.remve-img {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 99;
}
.seq-wrap {
  position: relative;
}
.seq-wrap .upld-img-wrap {
  width: 180px;
  height: 140px;
  margin-left: 30px;
  margin-bottom: 10px;
}
.seq-wrap .btn.btn-prim {
  width: 180px;
  margin-left: 30px;
  font-size: 11px;
  padding: 7px 20px;
}
.seq-wrap > span {
  position: absolute;
}
.btns-wrap-modal .btn-gray {
  margin-bottom: 20px;
  margin-left: 0;
}
.btns-wrap-modal .btn-gray img {
  margin-right: 20px;
}
.btns-small {
  display: flex;
  flex-wrap: wrap;
}
.btns-small .btn {
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  flex: 0 0 auto;
  width: calc(25% - 8px);
  margin-bottom: 0;
  min-width: initial;
  padding: 8px 12px;
  margin: 0 4px;
}
.btns-small {
  display: flex;
  flex-wrap: wrap;
}
.upld-img-modal-wrap .upld-img-wrap {
  margin: 0;
}
.modal-content .form-box.card-box {
  padding-left: 0;
  padding-right: 0;
  border: none;
}
.col-xl-3.col-lg-3.cust-col-3 {
  width: 100%;
}
.col-lg-6.cust-col-6 {
  width: 100%;
}
.form-box.card-box h3 {
  color: #222222;
  font-size: 15px;
  font-weight: 400;
  word-wrap: break-word;
  margin-bottom: 0;
}
.general-dashboard table td a {
  color: #222222;
  text-decoration: none;
}
.option-selected span {
  background: rgba(1, 34, 105, 0.07);
  border-radius: 15px;
  color: #012269;
  font-size: 10px;
  font-weight: 400;
  word-wrap: break-word;
  padding: 8px 30px 8px 10px;
  margin-right: 10px;
  margin-top: 10px;
}
.option-selected span {
  position: relative;
}
.option-selected span img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
}
.option-selected {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.col-lg-9.col-cust-screens-9 .seq-wrap .upld-img-wrap {
  width: 100%;
}
.col-lg-9.col-cust-screens-9 .seq-wrap .upld-img-wrap {
  width: 100%;
  margin: 10px 0px;
  height: 120px;
}
.col-lg-9.col-cust-screens-9 .seq-wrap {
  padding-bottom: 25px;
}
.video-top-bar .user-wrap.user-back-wrap > a {
  background: rgba(0, 0, 0, 0.25);
}
.dashboard-top-row.add-new-top-row.video-top-bar {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 99999;
}
.general-dashboard.vid-gernal {
  position: relative;
}
.vide-bar-bottom {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.25);
  padding: 35px 30px;
}
.video-box img.play-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
  cursor: pointer;
}
.video-box img.video-bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: 9999;
}
.video-box {
  height: 100vh;
}
.video-box video {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
}
.video-box.play-video video {
  z-index: 999999999;
}
.bar-code-box .img {
  background: white;
  border-radius: 10px;
  border: 0.5px #eeeeee solid;
  padding: 15px;
  margin-right: 20px;
}
.bar-code-text h3 {
  color: white;
  font-size: 20px;
  font-weight: 400;
  word-wrap: break-word;
}
.row-bar {
  display: flex;
  flex-wrap: wrap;
}
.bar-code-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-right: 40px;
}
.scanning-sec-wrap {
  width: 100%;
  background: rgba(255, 141, 141, 1);
  padding: 60px 0;
  text-align: center;
  border-radius: 10px;
}
.scan-box-wrap {
  background: white;
  border-radius: 10px;
  border: 0.5px #eeeeee solid;
  padding: 60px 60px 60px 40px;
}
.modal .scan-box-wrap {
  border: none;
}
.modal .modal-header {
  border: none;
}
.scan-box-wrap h5 {
  color: #999999;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
  margin-bottom: 0;
}
.scan-box-wrap h3 {
  color: #222222;
  font-size: 15px;
  font-weight: 700;
  word-wrap: break-word;
  margin-bottom: 35px;
}
.scan-box-wrap .scanning-section h5 {
  text-align: end;
  margin: 10px 0 16px;
  color: #012269;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
}
.scanning-sec-wrap.scan-gray-wrap {
  background: rgba(247, 247, 247, 1);
  padding: 40px 35px 30px;
}
.str-scan-sec {
  width: 150px;
  height: 150px;
  border-radius: 75px;
  background: #fff;
  margin: 0 auto 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.scanning-sec-wrap.scan-gray-wrap .btns-wrap {
  display: flex;
  justify-content: space-between;
}
.scanning-sec-wrap.scan-gray-wrap .btn.btn-prim {
  width: calc(50% - 15px);
}
.scan-cont li {
  color: #999999;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
}
.mx-five {
  margin-left: 5px;
  margin-right: 5px;
}
.ml-ten {
  margin-left: 10px;
}
.mr-ten {
  margin-right: 10px;
}
.my-thirty {
  margin-top: 30px;
  margin-bottom: 30px;
}
.media_bg {
  background: #eeeeee;
  border-radius: 20px;
  width: 100px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.media_bg.video_bg {
}
.media_bg.media_img_bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}
.media-selection h2 {
  color: #012269;
  font-size: 22px;
  font-weight: 700;
  word-wrap: break-word;
  margin-bottom: 95px;
}
.media-opt-wrap {
  background: white;
  border-radius: 10px;
  border: 0.5px #eeeeee solid;
  padding: 45px 70px 20px;
  margin: 0 40px;
}
.media-opt-wrap h3 {
  color: #222222;
  font-size: 15px;
  font-weight: 400;
  word-wrap: break-word;
  margin-top: 50px;
}
.media-footer p {
  color: #222222;
  font-size: 17px;
  font-weight: 400;
  word-wrap: break-word;
  margin-bottom: 0;
}
.media-footer p a {
  display: inline-block;
}
.media-footer p img {
  max-width: 50px;
}
.media-footer {
  position: absolute;
  width: 100%;
  bottom: 35px;
}
.profile-main-wrap {
  background: white;
  border-radius: 10px;
  border: 0.5px #eeeeee solid;
  padding: 30px 20px;
}
.col-lg-3.col-cust-3 {
  width: 175px;
}
.col-lg-9.col-cust-9 {
  width: calc(100% - 175px);
}
.img-profile-wrap {
  background: #eeeeee;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.img-profile-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.cont-profile-wrap h4 {
  color: #222222;
  font-size: 20px;
  font-weight: 400;
  word-wrap: break-word;
  margin-bottom: 17px;
}
.cont-detail a {
  color: #012269;
  font-size: 12px;
  font-weight: 400;
  word-wrap: break-word;
  text-decoration: none;
  margin-bottom: 17px;
  margin-right: 30px;
  display: inline-block;
}
.cont-profile-wrap p {
  color: #999999;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  word-wrap: break-word;
  margin-bottom: 0;
}
.cont-detail a img {
  margin-right: 10px;
  max-width: 14px;
}
.cont-row {
  margin-bottom: 48px;
}
.profile-details {
  display: flex;
  margin-bottom: 50px;
}
.profile-details .icons-wrap {
  background: rgba(107.54, 201.01, 107.54, 0.1);
  border-radius: 30px;
  width: 60px;
  height: 60px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-content {
  display: flex;
  align-items: center;
}
.profile-content span {
  color: #222222;
  font-size: 30px;
  font-weight: 400;
  word-wrap: break-word;
  margin-right: 5px;
}
.profile-content i {
  color: #999999;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  word-wrap: break-word;
  font-style: normal;
  display: inline-block;
}
.scan-profile-wrap h3 {
  color: #222222;
  font-size: 15px;
  font-weight: 400;
  word-wrap: break-word;
  margin-bottom: 28px;
}
.scan-profile-wrap img {
  max-width: 120px;
  margin-bottom: 55px;
}
.scan-profile-wrap .btn {
  max-width: 300px;
  display: block;
  margin: 0 auto 20px;
}
.icons-wrap.danger-box {
  background-color: rgba(201, 108, 158, 0.1);
}
.icons-wrap.warning-box {
  background-color: rgba(201, 158, 108, 0.1);
}
.icons-wrap.prim-box {
  background-color: rgba(108, 167, 201, 0.1);
}
.icons-wrap.success-box {
  background-color: rgba(108, 201, 108, 0.1);
}
.mob_btn_hambrgr {
  display: none;
  align-items: center;
  padding: 5px;
  justify-content: center;
  cursor: pointer;
}
/* live streaming */
.vide-wrap {
  width: 100%;
  height: 120px;
  background: #222222;
  border-radius: 10px;
  position: relative;
}
.col-lg-cust-5 {
  padding: 0 20px;
  width: 20%;
  margin-bottom: 40px;
}
.vide-wrap video {
  object-fit: contain;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  height: 100%;
  width: 100%;
}
.top-video-detail {
  color: white;
  font-size: 7px;
  font-weight: 400;
  word-wrap: break-word;
  position: absolute;
  top: 5px;
  left: 10px;
}
.bottom-video-detail {
  color: white;
  font-size: 7px;
  font-weight: 700;
  word-wrap: break-word;
  position: absolute;
  bottom: 5px;
  right: 10px;
}
.vid-placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-size: 25px;
  font-weight: 400;
  word-wrap: break-word;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  padding: 0 10px;
}
.defected-text {
  color: #ff5c00;
}
.form-box.card-box.appnt-set-box {
  padding: 20px;
}
.form-box.card-box.appnt-set-box h3 {
  color: #222222;
  font-size: 20px;
  font-weight: 400;
  word-wrap: break-word;
}
.form-box.card-box.appnt-set-box p {
  color: #999999;
  font-size: 12px;
  font-weight: 400;
  word-wrap: break-word;
}
.row-top-appnt {
  margin-bottom: 75px;
}
.conditions-appnt .appnt-alert {
  color: #46be32;
  font-size: 12px;
  font-weight: 700;
  word-wrap: break-word;
  padding: 8px 30px;
  min-width: 130px;
  border-radius: 15px;
  margin-left: 20px;
  display: inline-block;
  text-align: center;
}
.conditions-appnt .appnt-alert.success {
  background: rgba(70, 190, 50, 0.1);
  color: #46be32;
}
.conditions-appnt .appnt-alert.warning {
  background: rgba(237, 164, 22, 0.1);
  color: rgba(237, 164, 22, 1);
}
.conditions-appnt .appnt-alert.danger {
  background: rgba(237, 22, 22, 0.1);
  color: rgba(237, 22, 22, 1);
}
.form-box.card-box.appnt-set-box .details-wrap-appnt h3 {
  color: #222222;
  font-size: 20px;
  font-weight: 400;
  word-wrap: break-word;
  padding: 20px 0;
  border-bottom: 1px solid #eeeeee;
  border-top: 1px solid #eeeeee;
  margin-bottom: 40px;
}
.details-wrap-appnt h5 {
  color: #777777;
  font-size: 12px;
  font-weight: 400;
  word-wrap: break-word;
  margin-bottom: 5px;
}
.form-box.card-box .details-wrap-appnt h4 {
  color: #012269;
  font-size: 15px;
  font-weight: 700;
  word-wrap: break-word;
  margin-bottom: 50px;
}
.btns-wrap.appnt .btn {
  min-width: 150px;
  margin-left: 30px;
}
.inner-det-wrap {
  padding-right: 80px;
}
.inner-det-wrap .col {
  padding: 0 30px;
  flex: 0 0 auto;
  width: 100%;
}
.inner-det-wrap .col.col-name {
  width: 18%;
}
.inner-det-wrap .col.col-email {
  width: 31%;
}
.inner-det-wrap .col.col-contact {
  width: 22%;
}
.inner-det-wrap .col.col-date {
  width: 29%;
}
button#dropdownProf:after {
  display: none;
}
button.btn.dropdown-toggle,
button.btn.dropdown-toggle:focus,
button.btn.dropdown-toggle:active {
  outline: none !important;
  border: none;
}
ul.dropdown-menu.profile-drp-dwn {
  background: #fff;
  padding: 10px;
  width: auto;
}
.profile-drp-dwn li a {
  color: #222222;
  font-size: 15px;
  font-weight: 400;
  line-height: 12.5px;
  word-wrap: break-word;
  padding-top: 10px;
  padding-bottom: 10px;
  white-space: initial;
  text-decoration: none;
  display: inline-block;
  transition: 0.3s;
}
.profile-drp-dwn li a:hover {
  color: #012269;
}
.mob-logo {
  display: none;
}
.search-wrap.search-wrap form input {
  border: 1px solid rgba(1, 34, 105, 1);
  padding: 5px;
}
.search-wrap.search-wrap {
  display: flex;
  align-items: center;
  position: relative;
}
.filter-right-sec form {
  opacity: 0;
  visibility: hidden;
  /* width: 0; */
  transition: all 0.5s ease-in-out;
  position: absolute;
  top: 20px;
}
.active.filter-right-sec form {
  /* width: auto; */
  opacity: 1;
  visibility: visible;
}
.fltr-btn::after {
  display: none;
}
ul.dropdown-menu.filter-dwn {
  padding: 10px;
}
ul.dropdown-menu.filter-dwn li {
  color: #222222;
  font-size: 15px;
  font-weight: 400;
  line-height: 12.5px;
  word-wrap: break-word;
  padding-top: 10px;
  padding-bottom: 10px;
  white-space: initial;
  text-decoration: none;
  display: inline-block;
  transition: 0.3s;
}
.all-head {
  display: flex;
}
.img-profile-wrap {
  position: relative;
}
.edit-btn {
  position: absolute;
  background-color: rgba(1, 34, 105, 1);
  width: 28px;
  height: 28px;
  border-radius: 34px;
  display: flex;
  border: 3px solid #fff;
  justify-content: center;
  align-items: center;
  bottom: -20px;
  right: 0;
}
.edit-btn img {
  width: 12px;
  height: 12px;
}
.edit-btn input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 99;
}
.msg-line p span {
  color: #777777;
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  line-height: 17.5px;
  word-wrap: break-word;
}
.col.input-fld {
  position: relative;
}
.inpt-fld input[type="text"] {
  padding-right: 70px;
}
.emoji-wrap {
  position: absolute;
  right: 60px;
  top: 50%;
  transform: translateY(-50%);
}
.pin-wrap input[type="file"] {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  border: none;
  padding: 0;
}
.pin-wrap {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}
div#v-pills-tabContent,
div#v-pills-empty {
  height: 100%;
}
.empty-screen h3 {
  color: #222222;
  font-size: 15px;
  font-weight: 400;
  line-height: 18.75px;
  word-wrap: break-word;
  margin-top: 50px;
}
.empty-screen {
  height: 100%;
  background: #fff;
  text-align: center;
  border-radius: 10px;
}
.btn-hide {
  opacity: 0;
  position: absolute;
  height: 0;
  visibility: hidden;
  width: 0;
  z-index: -99;
  width: 0 !important;
}
.filter-right-sec label {
  color: #222222;
  font-size: 12px;
  font-weight: 400;
  word-wrap: break-word;
  margin-right: 5px;
}
.filter-wrap-chat .search-wrap {
  margin-right: 20px;
}
.filter-wrap-chat .search-wrap input {
  border-radius: 5px;
  background: #fff;
  padding: 10px;
  border: 1px solid rgba(238, 238, 238, 1);
  padding-right: 40px;
  max-width: 140px;
}
.filter-wrap-chat .search-box {
  margin: 0;
  position: absolute;
  right: 15px;
  width: auto;
  height: auto;
}
.date-wrap input {
  border-radius: 5px;
  background: #fff;
  padding: 10px;
  border: 1px solid rgba(238, 238, 238, 1);
  padding-right: 0;
  margin-right: 20px;
  min-width: 130px;
  color: #012269;
  font-size: 12px;
  font-weight: 400;
  word-wrap: break-word;
  min-height: 46px;
}
.date-wrap input[type="date"]::-webkit-calendar-picker-indicator {
  color: transparent;
  background: none;
  z-index: 1;
}

.date-wrap {
  position: relative;
}
.date-wrap input[type="date"]:before {
  color: transparent;
  background: none;
  display: block;
  content: "";
  width: 12px;
  height: 12px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 26px;
  color: #999;
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: center;
  background-size: 12px;
  background-image: url(images/calendar.svg);
}

.filter-select select {
  border-radius: 5px;
  background-color: #fff;
  padding: 10px;
  border: 1px solid rgba(238, 238, 238, 1);
  padding-right: 0;
  margin-right: 20px;
  min-width: 130px;
  color: #012269;
  font-size: 12px;
  font-weight: 400;
  word-wrap: break-word;
  min-height: 46px;
}
.filter-select select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("images/drop-select.svg");
  background-repeat: no-repeat;
  background-position: right 10px center;
}
/* media queries */
@media (max-height: 650px) {
  .media-footer {
    position: unset;
    width: 100%;
    padding-bottom: 35px;
  }
}
@media (min-width: 992px) {
  .col-xl-3.col-lg-3.cust-col-3 {
    width: 100%;
  }
  .col-lg-6.cust-col-6 {
    width: 100%;
  }
  .moda .label-wrap {
    margin-bottom: 15px;
  }
  .col-lg-3.col-cust-screens-3 {
    width: 20.8%;
  }
  .col-lg-9.col-cust-screens-9 {
    width: 79.2%;
  }
}
@media (min-width: 1199px) {
  .col-xl-3.col-lg-3.cust-col-3 {
    width: 230px;
  }
  .col-lg-6.cust-col-6 {
    width: calc(100% - 460px);
  }
  .col-lg-3.col-cust-screens-3 {
    width: 20.8%;
  }
  .col-lg-9.col-cust-screens-9 {
    width: 79.2%;
  }
}
@media (min-width: 1399px) {
  .col-lg-3.col-cust-screens-3 {
    width: 20.8%;
  }
  .col-lg-9.col-cust-screens-9 {
    width: 79.2%;
  }
}
@media (max-width: 1500px) {
  .inner-det-wrap {
    padding-right: 0px;
  }
  .inner-det-wrap .col.col-name {
    width: 18%;
  }
  .inner-det-wrap .col.col-email {
    width: 31%;
  }
  .inner-det-wrap .col.col-contact {
    width: 22%;
  }
  .inner-det-wrap .col.col-date {
    width: 29%;
  }
}
@media (max-width: 1399px) {
  .form-box.card-box .details-wrap-appnt h4 {
    font-size: 13px;
  }
  .btn.tb-btn,
  .link-edit.tb-btn {
    padding: 5px 15px;
    min-width: inherit;
    margin-left: 10px;
  }
  .col.col-sidebar {
    width: 225px;
    padding: 0;
  }
  section.sidebar_wrap ul li {
    padding: 11px 20px;
  }
  .col.col-dashboard {
    width: calc(100% - 225px);
    padding: 0 20px 0 10px;
    margin-left: 225px;
  }
  .sidebar_wrap {
    padding: 30px 10px 30px 20px;
  }
  .general-dashboard table td {
    padding: 10px 10px;
  }
  .general-dashboard table th {
    padding: 10px 10px;
  }
  .form-btn-wrap .btn {
    min-width: 200px;
  }
  .form-box.card-box {
    padding: 30px 15px;
  }
  .inpt-wrap input,
  .inpt-wrap select,
  .inpt-wrap textarea,
  .file-wrap {
    padding: 10px 20px;
    font-size: 11px;
    line-height: 17px;
  }
  .form-box.card-box p {
    font-size: 11px;
    line-height: 11px;
  }
}
@media (max-width: 1200px) {
  .scan-img-appnt {
    margin-top: 25px;
  }
  .form-box.card-box.appnt-set-box h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .conditions-appnt .d-flex.align-items-center.justify-content-end {
    justify-content: left !important;
    margin-top: 25px;
  }
  .col-lg-cust-5 {
    width: 25%;
  }
  .col.col-dashboard {
    width: calc(100% - 250px);
    padding: 0px 20px 0 10px;
    margin-left: 250px;
  }
  .col.col-sidebar {
    width: 250px;
  }
  section.sidebar_wrap ul li {
    padding: 13px 10px;
  }
  .btn.tb-btn,
  .link-edit.btn.tb-btn {
    padding: 5px 10px;
    min-width: inherit;
    margin-left: 3px;
    font-size: 10px;
  }
  .general-dashboard table {
    min-width: 950px;
  }
}
@media (max-width: 991px) {
  .mob-logo {
    display: block;
  }
  header .col-lg-6 {
    order: 2;
  }
  header .col-lg-6.text-end {
    order: 1;
  }
  .wel-not {
    padding: 75px 0 0;
  }
  html body .prof-sett {
    position: fixed;
    width: 100%;
    background: #fff;
    top: 0;
    left: 0;
    padding: 15px 10px;
    justify-content: space-between;
    z-index: 9;
  }
  .det-prof {
    display: none;
  }
  .mob_btn_hambrgr {
    width: 98px;
  }
  .logo-wrap.mob-logo img {
    width: 75px;
  }
  .all-head {
    display: flex;
  }
  section.sidebar_wrap .logo-wrap {
    opacity: 0;
    visibility: hidden;
    position: relative;
    z-index: -1;
  }

  .inner-det-wrap .col.col-name {
    width: 100%;
    padding: 0 30px !important;
  }
  .inner-det-wrap .col.col-email {
    width: 100%;
    padding: 0 30px !important;
  }
  .inner-det-wrap .col.col-contact {
    width: 100%;
    padding: 0 30px !important;
  }
  .inner-det-wrap .col.col-date {
    width: 100%;
    padding: 0 30px !important;
  }
  .scan-img-appnt {
    text-align: center;
  }
  .conditions-appnt .appnt-alert {
    margin-left: 0;
    margin-top: 15px;
    padding: 7px 30px;
    font-size: 10px;
  }
  .inner-det-wrap {
    padding-right: 0;
  }
  .row-top-appnt {
    margin-bottom: 25px;
  }
  .form-box.card-box.appnt-set-box .details-wrap-appnt h3 {
    font-size: 15px;
    padding: 10px 0;
    margin-bottom: 30px;
  }
  .form-box.card-box .details-wrap-appnt h4 {
    margin-bottom: 20px;
  }
  .inner-det-wrap .col {
    width: 100%;
  }
  .btns-wrap.appnt .btn {
    margin: 5px 0 !important;
  }
  .btns-wrap.appnt {
    text-align: center;
  }
  .scan-img-appnt {
    margin-top: 20px;
  }
  .conditions-appnt .d-flex.align-items-center.justify-content-end {
    flex-wrap: wrap;
    justify-content: center !important;
  }
  .user-wrap h3 {
    font-size: 18px;
  }
  .form-box.card-box.appnt-set-box h3 {
    color: #222222;
    font-size: 12px;
    font-weight: 400;
    word-wrap: break-word;
    margin-bottom: 6px;
  }
  .form-box.card-box.appnt-set-box p,
  .user-wrap p {
    font-size: 8px;
  }
  .col.col-sidebar {
    transition: 0.5s linear;
  }
  .col.col-sidebar.sidebar.active {
    left: 0;
  }
  .mob_btn_hambrgr {
    display: flex;
  }
  .cont-detail.d-flex {
    flex-wrap: wrap;
  }
  .cont-detail a {
    width: 100%;
  }
  .media-opt-wrap {
    margin-bottom: 15px;
  }
  .media-selection h2 {
    margin-bottom: 25px;
  }
  .col-lg-3.col-cust-3,
  .col-lg-9.col-cust-9 {
    width: 100%;
  }
  .media-footer {
    position: unset;
    width: 100%;
    padding-bottom: 35px;
  }
  .scanner {
    text-align: center;
    margin: 0 0 20px;
  }
  .scan-box-wrap {
    padding: 30px 60px 30px 40px;
  }
  .label-wrap {
    margin-bottom: 15px;
  }
  .upld-img-wrap {
    height: 250px;
  }
  .modal .main-img {
    height: 500px !important;
    margin-top: 30px;
  }
  .modal .label-wrap {
    margin-bottom: 20px;
  }
  .mob-1 {
    order: 1;
  }
  .mob-2 {
    order: 2;
  }
  .cont_form_wrap {
    padding: 30px;
  }
  .form-wrap input,
  .form-wrap select {
    margin-bottom: 10px;
  }
  .col.col-sidebar {
    width: 250px;
    position: fixed;
    left: -100%;
    z-index: 9;
  }
  .col.col-dashboard {
    width: 100%;
    padding: 0 20px;
    margin-left: 0;
  }
  .wel-not h1 {
    text-align: center;
    font-size: 16px;
  }
  .prof-sett {
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .mob-logo {
    display: block;
  }
  header .col-lg-6 {
    order: 2;
  }
  header .col-lg-6.text-end {
    order: 1;
  }
  .wel-not {
    padding: 75px 0 0;
  }
  .prof-sett {
    position: fixed;
    width: 100%;
    background: #fff;
    top: 0;
    left: 0;
    padding: 15px 10px;
    justify-content: space-between;
    z-index: 9;
  }
  .det-prof {
    display: none;
  }
  .mob_btn_hambrgr {
    width: 98px;
  }
  .logo-wrap.mob-logo img {
    width: 75px;
  }
  .all-head {
    display: flex;
  }
  section.sidebar_wrap .logo-wrap {
    opacity: 0;
    visibility: hidden;
    position: relative;
    z-index: -1;
  }

  .btns-wrap.appnt.btns-wrap-appt .btn {
    margin: 5px 10px !important;
    min-width: 40%;
  }
  .btns-wrap.appnt.btns-wrap-appt {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  .form-box.card-box.appnt-set-box h3,
  .form-box.card-box.appnt-set-box p {
    text-align: center;
  }
  .col-lg-cust-5 {
    width: 50%;
    padding: 0 10px;
  }
  .scanner {
    text-align: center;
    margin: 0 0 20px;
  }
  .bar-code-box .img {
    padding: 5px;
    margin-right: 10px;
  }
  .bar-code-box .img img {
    max-width: 30px;
    width: 100%;
  }
  .bar-code-box {
    margin-right: 20px;
  }
  .bar-code-text h3 {
    font-size: 16px;
  }
  .vide-bar-bottom {
    padding: 15px 20px;
  }
  .video-box img.play-btn {
    max-width: 100px;
  }
  .justify-content-center-mob {
    justify-content: center;
  }
  .seq-wrap > span {
    position: absolute;
    left: 0;
  }
  .seq-wrap {
    text-align: center;
    margin: 0 auto;
  }
  .seq-wrap .upld-img-wrap {
    margin: 20px auto;
  }
  .seq-wrap .btn.btn-prim {
    margin-left: 0;
  }
  .col.col-video-screen,
  .col.col-user-accs,
  .col.col-user-msgs {
    width: 100%;
  }
  .user-wrap {
    margin-bottom: 20px;
  }
  .vide-sec-whole-wrap {
    max-height: initial;
    height: auto;
  }
  .bottom-bar-call.seperat-row {
    position: unset;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .label-wrap {
    margin-bottom: 15px;
  }
  .form-btn-wrap .btn {
    min-width: initial;
    margin-left: 10px;
  }
  .call-detail-bar {
    padding: 20px;
    display: block;
  }
  .incoming-call-sec h3 {
    color: black;
    font-size: 17px;
    font-weight: 700;
    line-height: 38.5px;
    letter-spacing: 1.2px;
    word-wrap: break-word;
    margin-bottom: 30px;
  }
  .icons-wrap-options {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0 0;
  }
  .incoming-call-sec .rcvr-naming h3 {
    font-size: 20px;
  }
  .rcvr-naming p {
    color: rgba(0, 0, 0, 0.5);
    font-size: 16px;
  }
}
@media (max-width: 575px) {
  .col-lg-cust-5 {
    width: 75%;
    padding: 0 10px;
  }
  .scan-box-wrap {
    padding: 30px 30px;
  }
  .btn,
  .btn.btn-prim {
    margin-bottom: 10px;
  }
  .bar-code-box .img img {
    max-width: 20px;
    width: 100%;
  }
  .bar-code-box .img {
    padding: 7px;
    margin-right: 5px;
    margin-bottom: 10px;
  }
}

/* additional css */

::-webkit-scrollbar {
  width: 1px;
}
.qrCodeImagelarge {
  width: 200px; /* Adjust this to your desired larger size */
  height: 200px; /* Adjust this to your desired larger size */
  cursor: pointer;
}

#html5-qrcode-button-camera-stop {
  background: #1e1edc !important;
  border: 1px solid #1e1edc;
  color: white !important;
  border-radius: 5px !important;
  padding: 5px !important;
}
#html5-qrcode-button-camera-start {
  background: #1e1edc !important;
  border: 1px solid #1e1edc;
  color: white !important;
  border-radius: 5px !important;
  padding: 5px !important;
}
#html5-qrcode-anchor-scan-type-change {
  display: none !important;
}

@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300|Oswald);

.counter-sec {
  font-family: "Roboto Condensed", cursive;
  text-align: center;
  line-height: 100px;
  font-size: 28px;
  font-weight: normal;
  top: 50%;
  left: 50%;
  color: #939393;
}
.counter-sec .counter {
  font-size: 50px;
  width: 80px;
  height: 50px;
  font-family: "Oswald", sans-serif;
  display: inline-block;
  padding: 0 30px 0 10px;
  perspective: 200px;
  position: relative;
  top: -7px;
}
.counter-value {
  padding: 20px;
  border: 1px solid #1a1a91;
  border-radius: 50px;
  color: red;
}
.user-video {
  border-radius: 10px;
  border: 1px white solid;
}

.vide-img-wrap video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

/* new css*/
.persona-img {
  width: 108px;
  height: 92px;
  border-radius: 10px;
  /* border: 1px white solid */
}
.small-screen {
  /* background: rgba(255, 255, 255, 0.50);
    border-radius: 10px; */
  position: absolute;
  top: 20px;
  right: 20px;
  min-width: 100px;
  text-align: center;
}
