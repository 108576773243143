.sidebar_wrap ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.card_wrap {
  border-radius: 10px;
  border: 0.5px #eeeeee solid;
  display: flex;
  align-items: center;
  background: #fff;
  padding: 20px;
  margin: 0 8px 40px 8px;
}
.card_icon {
  background: rgba(132.83, 157.45, 34.38, 0.1);
  border-radius: 10px;
  width: 40px;
  height: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}
.card_head {
  color: #222222;
  font-size: 17px;
  font-weight: 400;
  word-wrap: break-word;
  margin-bottom: 0;
}
.card_text {
  color: #999999;
  font-size: 12px;
  font-weight: 400;
  word-wrap: break-word;
  margin-bottom: 0;
}
.qr_roles_wrap {
  margin-bottom: 40px;
}
.card_box {
  background: white;
  border-radius: 10px;
  border: 0.5px #eeeeee solid;
  padding: 20px;
  height: 100%;
}
.card_box h2 {
  color: black;
  font-size: 20px;
  font-weight: 400;
  word-wrap: break-word;
  margin-bottom: 0;
}
.card_box p {
  color: #999999;
  font-size: 12px;
  font-weight: 400;
  word-wrap: break-word;
  margin-bottom: 20px;
}
.tables_wrap th {
  color: #222222;
  font-weight: 700;
  word-wrap: break-word;
  border-bottom: none;
  padding: 0.5rem 20px;
}
.tables_wrap th:nth-child(1) {
  padding-left: 0;
}
.tables_wrap td {
  color: #222222;
  font-size: 12px;
  font-weight: 400;
  word-wrap: break-word;
  padding: 20px;
}
.tables_wrap td:nth-child(1) {
  padding-left: 0;
}
.tables_wrap td:nth-last-child(1) {
  padding-right: 0;
  text-align: right;
}
.tables_wrap.tables_role td:nth-last-child(1) {
  text-align: left;
}
.tab_body_wrap_role h2 {
  color: #222222;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  word-wrap: break-word;
  padding: 0.5rem;
}
.tab_body_wrap_role p {
  color: #222222;
  font-size: 12px;
  font-weight: 400;
  word-wrap: break-word;
  margin-bottom: 0;
  padding: 0.5rem;
}
.delete_modal h3 {
  color: black;
  font-size: 16px;
  font-weight: 400;
  word-wrap: break-word;
  margin-bottom: 0;
  text-align: center;
  padding: 20px 0;
  color: #b60000;
}

@media (max-width: 1399px) {
  .tables_wrap td {
    color: #222222;
    font-size: 11px;
  }
}

@media (max-width: 1200px) {
  .card_icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  .card_icon img {
    max-width: 70%;
  }
  .card_wrap {
    margin: 0 0px 20px;
    padding: 10px;
  }
}
