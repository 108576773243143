 .wrapper_canvas{
  position: relative;
display: inline-block;
margin: 0 auto;
  }

 .amm_canvas{
  position:relative;
   z-index: 0;
   display: none;
  

 }
 .imageBoxDR,
 .boxDR{
 z-index: 2;
 background-color: rgb(0, 0, 0);
 /*RGBa with 0.6 opacity*/ 
background-color: rgba(0, 0, 0, 0);
 /*For IE 5.5 - 7*/
filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#00000000, endColorstr=#00000000);
 /*For IE 8*/
-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#00000000, endColorstr=#00000000)";  
 
 }
 .virtual_textfill{
visibility: hidden;
 }

 .amm-color-picker{
    -webkit-appearance: none;
    border: none;
    width: 32px;
    height: 32px;
    margin: 2px 5px;
}
.amm-color-picker::-webkit-color-swatch-wrapper {
	padding: 0;
}
.amm-color-picker::-webkit-color-swatch {
	border: none;
}

 #boxDR0{
    
 }
 #boxDR1{
    bottom: 0;
 }

 .wrapper-memes-main{
    /*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-pack: justify;
-ms-flex-pack: justify;
justify-content: space-between; 
 }

 .wrapper-memes-main .ui-resizable-ne,
 .wrapper-memes-main .ui-resizable-se,
 .wrapper-memes-main .ui-resizable-nw,
 .wrapper-memes-main .ui-resizable-sw{ 
    border:1px solid white;
     width: 15px;
     height: 15px;
   background-color: rgb(0, 0, 0);
 /*RGBa with 0.6 opacity*/ 
background-color: rgba(0, 0, 0, 0.6);
 /*For IE 5.5 - 7*/
filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000);
 /*For IE 8*/
-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000)";  
 }
 .wrapper-memes-main .ui-resizable-se
 {
     margin-bottom: -6px;
     margin-right: -4px;
 }
 
 .wrapper-memes-preview{
    text-align: center;
    width: 48%;
    padding: 10px;
 }
 .wrapper-memes-operations{
    width: 48%;
    padding: 10px;
 }
  .wrapper-memes-operations .amm-text{
     width: 100%;
 }
 .wrapper-memes-operations .wrapper-text{
     margin-bottom: 10px;
     display: -webkit-box;   
      display: -ms-flexbox;    
      display: flex;
 }
 #wrapper_canvas_background{
    display: inline-block; 
    width: 100%;
 }
 #wrapper_canvas_background:hover .imageBoxDR,
 #wrapper_canvas_background:hover .boxDR{
     opacity: 1!important;
    
     
 }
 
 .wrapper-memes-preview{

 }
 .wrapper-memes-preview .imageBoxDR:hover,
 .wrapper-memes-preview .boxDR:hover{
     cursor:move;
 }
 .clear_both{clear:both;}
 
.amm-brushes-picker:hover, .picker:hover, .colorBox:hover {
box-shadow: 0 0 3px 1px #999;
}
.amm-brushes-picker,
.fontOps .picker {
display: inline-block;
height: 24px;
line-height: 24px;
}

.fontOps {
    float: left;
    width: 42%;
    height: 44px;
    max-height: 44px;
    padding: 2px;
    color: #333;
    background: #ccc;
    border: 2px solid #ccc;
    display: flex;
    align-items: center;
}

.amm-brushes-picker,
.picker {
position: relative;
display: inline-block;
vertical-align: middle;
width: 24px;
height: 24px;
margin: 0 4px;
cursor: pointer;
box-shadow: 0 0 3px 1px #ccc;
line-height: 24px;
}

.wrapper-text .amm-text{
float:left;
vertical-align: top;
padding: 2px;
width: 58%;
height: 44px;
border-width: 2px;
border-style: solid;
resize: none;
line-height: 2.5;
}

.fontOps .ow {
    color: #666;
    height: 32px;
    line-height: 16px;
    width: 44px;
    padding: 2px 5px;
    border-color: #fff;
    margin-left: 1px;
    border: 1px solid #ccc;
    margin: 2px 5px;
    text-align: center;
    /* -moz-appearance: textfield; */
}
.fontOps .ow::-webkit-outer-spin-button,
.fontOps .ow::-webkit-inner-spin-button {
  /* -webkit-appearance: none; */
  margin: 0;
}
 

.colorBox {
display: inline-block;
vertical-align: top;
width: 24px;
height: 24px;
margin: 5px;
cursor: pointer;
box-shadow: 0 0 3px 1px #ccc;
}
.colorPanelBrushes,
.colorPanel{
    width: 180px;
    height:114px;
    padding: 5px; 
}
.amm-brushes-picker{
    float: right;
}
.pickpop-brushes,
.pickpop{
    display: none;
background: gray;
position: absolute;
z-index: 2;
 
}

.wrapperRenderPopupMoreOptions{
    width: 300px;
 
 
padding: 10px;
 display: none;
background: #ccc;
position: absolute;
z-index: 2;
margin-top: 39px;
}

#colorSelectorBrushes div {
position: absolute;
top: 3px;
left: 3px;
width: 30px;
height: 30px;
/* background: url(../../../libraries/colorpicker/images/select.png) center; */
}

#colorSelectorBrushes {
position: relative;
width: 36px;
height: 36px;
/* background: url(../../../libraries/colorpicker/images/select.png); */
}

#colorSelectorBrushes,
#colorSelector{
    cursor: pointer;
    margin: 5px;
}
.colorpickerbrushes,
.wrapper-memes-operations .colorpicker{
    display: none;
}
body {
    text-align:inherit!important;
     overflow:auto; 
}
 
.wrapperRenderPopupMoreOptions label{
font-weight: bold;
font-size: 14px;
color: black;
width: 90px;
}
.wrapperRenderPopupMoreOptions select{
    width: 75px; 
}
.wrapperInputFontFamily{
width: 170px;
display: inline-block;
}
.wrapperInputFontSize{
 
display: inline-block;   
}
#amm-input-upload-image-meme-responsive,
.wrapper-memes-preview-operations .amm-addImage{
    display: none;
}
.wrapper-memes-preview-operations {
margin: 10px 0;
}
.imageBoxDR.ForceHide,
.boxDR.ForceHide{
visibility:hidden;
}
.amm_canvas.MouseCrossHair:hover{
    cursor: crosshair;
    
}
 
.wrapper-amm-stop-brushes{
    display: none;
}
.wrapper-amm-stop-brushes.show{
  display: -webkit-box!important;    display: -ms-flexbox!important;    display: flex!important;
  justify-content: center;
}
#amm-stop-brushing, .amm-brushes-picker{
    float: right;
}
input#amm-clear-brush {
    float: left;
}
.wrapper-memes-preview-operations input {

padding: 3px;
}
.wrapper_select_image_amm{
    cursor: pointer;
}

.wrapper_thumbnail_meme{
    display: -webkit-box;    display: -ms-flexbox;    display: flex;
}
.stButton .stFb, .stButton .stTwbutton, .stButton .stMainServices{
    height: 22px!important;
}
.wrapper-popup-download-canvas{
    text-align: center;
}
.view-id-memes{
    margin-top: 10px;
}
.amm-popup-trigger{
    cursor:pointer;
}
.moreOption{
    text-decoration: underline;
    background-image: url(../images/remove.png)!important;
    display: inline-block!important;
    width: 32px;
    background-position: center;
    height: 32px;
    background-size: auto;
    background-repeat: no-repeat;
    background-color: transparent!Important;
}
.delete-box-amm{
    position: absolute;
    margin-top: -29px;
    left: 50%;
    margin-left: -10px;
    background-image: url(../images/remove.png)!important;
    display: inline-block!important;
    width: 20px;
    background-position: center;
    height: 30px;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent!Important;
    border: none;
    cursor: pointer;
}
.canvas_loading
{

 width: 100%;
height: 350px;
color: white;
display: flex;
justify-content: center;
align-items: center;
position: relative;
top: 0;
z-index: 10;
margin: 10px 0;
   /* Fallback for web browsers that doesn't support RGBa */
    background: rgb(0, 0, 0) transparent;
    /* RGBa with 0.6 opacity */
    background: rgba(0, 0, 0, 0.9);
    /* For IE 5.5 - 7*/
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#00000060, endColorstr=#99000060);
    /* For IE 8*/
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000)";

}
.canvas_loading img{
    margin-left: auto;
margin-right: auto;
/* text-align: center; */
display: inherit;
position: relative;
top: 120px;
}
.amm-text-boxes{
    margin: 10px 0;
}
/* .view-id-memes{
    display: none;
} */
.view-id-memes.view-display-id-block_1
{
    margin-bottom: 10px;
    overflow: auto;
     
}
.amm_canvas{
    width: 100%;
}


.wrapper-thumbnail-merge-images{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: start;
    -webkit-box-align: center;
    -ms-flex-align: center;
            align-items: center;
            margin: 10px 1px;
}

.wrapper-select-merge-image-amm{
    cursor: pointer;
    margin: 2px 10px;
}

.wrapper-select-merge-image-amm img{
    display: inherit;
}

.ui-resizable-se {
    cursor: se-resize;
    width: 12px;
    height: 12px;
    right: 1px;
    bottom: 1px;
}

.ui-resizable-sw {
    cursor: sw-resize;
    width: 9px;
    height: 9px;
    left: -5px;
    bottom: -5px;
}

.ui-resizable-ne {
    cursor: ne-resize;
    width: 9px;
    height: 9px;
    right: -5px;
    top: -5px;
}

.ui-resizable-nw {
    cursor: nw-resize;
    width: 9px;
    height: 9px;
    left: -5px;
    top: -5px;
}
.ui-resizable-handle {
    position: absolute;
    font-size: .1px;
    display: block;
}
.btn{
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.428571429;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    color: #333;
    background-color: #fff;
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}

@media only screen and (max-width: 800px) {
 body{
    width: 100%;
 }
     .wrapper-memes-main{
        display: block;
     }

     .wrapper-memes-operations,
     .wrapper-memes-preview{
         width: 100%;
         padding: 5px;
     }

     .wrapperRenderPopupMoreOptions{
        margin-top: 0px!important;
        top: 50%;
        position: fixed;
        border: 1px solid grey;
        width: 90%;
        left: 5%!important;
       }
  }